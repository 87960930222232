@import "../variables"

.ScrollButton_class, .RegisterButton_class

    text-align: center
    display: flex
    justify-content: center

    .buttonWrapper
        text-align: center
        display: flex
        justify-content: center
        .box
            cursor: pointer 
            width: 150px


.InspiniaScrollButton
    .btnSubscribe
        font-weight: 600 !important
        font-size: 26px !important
        position: relative
        background-color: #96b200 !important
        border-width: 0 
    @media only screen and (max-width: 580px)
        .btnSubscribe
            font-size: 18px !important
    .container > .row
        text-align: center !important
        padding-right: 0 !important
        padding-left: 25px !important

    .box
        &:before
            display: table
            content: ""
            line-height: 0
        &:after
            display: table
            content: ""
            line-height: 0
            clear: both

    @media (min-width: 768px) and (max-width: 991px)
        .box
            display: inline-block
            width: 50%

    .btn-primary
        display: inline-block
        position: relative
        font-size: 15px
        line-height: 19px
        font-weight: 500
        background: #96b200
        color: #fff
        text-transform: uppercase
        -webkit-transition: 0.4s
        transition: 0.4s
        padding: 41px 33px 43px 37px
        letter-spacing: -0.5px
        margin-left: -100px
        &:hover
            background: #2c2c2d !important
            background-color: #2c2c2d !important
        &:before
            position: absolute
            top: 0
            right: -63px
            font-size: 30px
            line-height: 35px
            z-index: 1
            -webkit-transition: 0.4s ease all
            transition: 0.4s ease all
            background: #2c2c2d
            padding: 34px 5px 34px 31px
        &:hover:before
            right: -75px
            padding: 34px 2px 34px 46px
            background: #96b200
        &:after
            width: 0
            height: 0
            border-style: solid
            border-width: 52px 0 52px 36px
            border-color: transparent transparent transparent #2c2c2d
            content: ''
            position: absolute
            display: inline-block
            top: 0
            right: -36px
            -webkit-transition: 0.4s ease all
            transition: 0.4s ease all
            pointer-events: none
        &:hover:after
            // right: -65px
            border-color: transparent transparent transparent #96b200

    @media (min-width: 320px) and (max-width: 767px)
        .btn-primary
            width: 200px !important
            height: 103px !important

    button
        font-family: sans-serif
        font-size: 100%
        line-height: 1.15
        margin: 0
        overflow: visible
        text-transform: none
        -webkit-appearance: button

    div
        &::first-letter, &::first-line
          text-shadow: none !important
          -webkit-box-shadow: none !important
          box-shadow: none !important

        &:focus
          outline: 1px dotted
          outline: 5px auto -webkit-focus-ring-color
          line-height: inherit

    .row
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        -webkit-flex-wrap: wrap
        -ms-flex-wrap: wrap
        flex-wrap: wrap
        margin-right: -15px
        margin-left: -15px

    @media (min-width: 576px)
        .row
            margin-right: -15px
            margin-left: -15px

    @media (min-width: 768px)
        .row
            margin-right: -15px
            margin-left: -15px

    @media (min-width: 992px)
        .row
            margin-right: -15px
            margin-left: -15px

    @media (min-width: 1200px)
        .row
            margin-right: -15px
            margin-left: -15px

    .btn-primary
        color: #fff
        &:hover
            color: #fff
            background-color: #025aa5
            border-color: #01549b
        &:focus
            -webkit-box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.5)
            box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.5)
        &:active
            color: #fff
            background-color: #025aa5
            background-image: none
            border-color: #01549b

    .animated
        -webkit-animation-duration: 1s
        animation-duration: 1s
        -webkit-animation-fill-mode: both
        animation-fill-mode: both

    .fadeInLeft
        -webkit-animation-name: fadeInLeft
        animation-name: fadeInLeft


    button
        color: inherit
        font: inherit
        margin: 0
        overflow: visible
        text-transform: none
        -webkit-appearance: button
        cursor: pointer
        &::-moz-focus-inner
            border: 0
            padding: 0

    div
        margin: 0
        padding: 0

    @-webkit-keyframes fadeInLeft
        0%
            opacity: 0
            -webkit-transform: translate3d(-100%, 0, 0)
            transform: translate3d(-100%, 0, 0)

        to
            opacity: 1
            -webkit-transform: none
            transform: none


    @keyframes fadeInLeft
        0%
            opacity: 0
            -webkit-transform: translate3d(-100%, 0, 0)
            transform: translate3d(-100%, 0, 0)

        to
            opacity: 1
            -webkit-transform: none
            transform: none

.BorderButton
    border-radius: 3px
    border: solid 3px
    cursor: pointer
    position: relative
    transition: color .7s ease-out, background .7s ease-out, transform .4s ease-out, border-color .7s ease-out, border .7s ease-out
    button
        cursor: pointer 
        display: flex
        @include filler
        border: none
        background: transparent
        span
            display: flex

    
    .Icon_class
        justify-content: center
        align-items: center
            