body
  margin: 0
  padding: 0
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"

.refreshCache_wrapper
  .refreshCacheButton
    margin-top: 2rem
    cursor: pointer
    border: solid 1px rgba(0,0,0,.3)
    border-radius: 5px
    padding: 5px 10px
    &:hover
      background: rgba(0,0,0,.05)