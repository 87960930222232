@import "style_functions"

// COLOR
$theme_white: #eaedf2
$theme_light_blue: #EBF2FA
$theme_beige: #F0FFCE
$theme_black: #51555D
$theme_grey: #e6e6e8
$theme_softblack: #2B303A
$theme_green: #A29F15
$theme_red: #FF3C38
$theme_orange: #f2713e
$theme_brown: #97743a
$theme_blue: #26408B
$theme_RA: #26408B

// ZZ COLORS
$zz_green: #96B200

// FONTS
$font_huge: 1.4rem
$font_title: 1.1rem
$font_big: 1rem
$font_medium: .9rem
$font_normal: .8rem
$font_small: .75rem
$font_tiny: .7rem

// ZINDIZED
$z_snapshot: 9000
$z_gallery: 4000
$z_modal: 3500
$z_large: 3000
$z_medium: 2000
$z_normal: 600
$z_small: 400
$z_tiny: 200
$z_zero: 0
$z_neg: -100
$z_neg_strong: -200

$z_editing_bg: $z_tiny
$z_panel: $z_large
$z_panel_drag: $z_panel + 10
$z_panel_above: $z_panel + 50
$z_sfContext: $z_panel_above
$z_pageElement: $z_normal
$z_pageElement_foreground: $z_pageElement + 10
$z_modal: $z_large

    
// SET THE PANEL COLORS
// $main_color: #275D71
$main_color: $theme_grey
$secondary_color: #a55000
$font_color: $theme_black

  
@mixin bounce() 
  0%,
  20%,
  50%, 
  80%,
  100%
    transform: translateY(0)

  40%
    transform: translateY(-30px)

  60%
    transform: translateY(-15px)
  
@keyframes bounce 
  @include bounce

@mixin pulseAndShadow($amount, $color)
  animation: pulseAndShadow 1s infinite

  @keyframes pulseAndShadow
    0%
      @include scale(1)
      box-shadow: 0 0 0 0 #{$color}
    70%
      box-shadow: 0 0 0 3px #{$color}
      @include scale($amount)
    100%
      box-shadow: 0 0 0 0 #{$color}
      @include scale(1)


@mixin pulseShadow($color)
  animation: pulseShadow 2s infinite

  @keyframes pulseShadow
    0%
      box-shadow: 0 0 0 0 #{$color}
    70%
      box-shadow: 0 0 0 3px #{$color}
    100% 
      box-shadow: 0 0 0 0 #{$color} 


@mixin pulse($amount, $color:false)
  cursor: pointer
  animation: pulse 1s infinite

  @keyframes pulse
    0%
      @include scale(1)
    70%
      @include scale($amount)
      @if $color
        color: $color
        100%
          @include scale(1)


@mixin filler 
  width: 100%
  height: 100%

@mixin trans_centerXY
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)

@mixin trans_centerY
  position: absolute
  top: 50%
  transform: translateY(-50%)

@mixin trans_centerX
  position: absolute
  left: 50%
  transform: translateX(-50%)

@mixin imageContain
  max-height: 100%
  max-width: 100%
  object-fit: contain

@mixin imageCover
  object-fit: cover
  @include filler
    
@mixin centeredFlex
  display: flex
  justify-content: center
  align-items: center

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%))
  ::-webkit-scrollbar 
    width: $size
    height: $size
  
  ::-webkit-scrollbar-thumb 
    background: $foreground-color
  
  ::-webkit-scrollbar-track 
    background: $background-color

  // For Internet Explorer
  body 
    scrollbar-face-color: $foreground-color
    scrollbar-track-color: $background-color

// Browser Prefixes
@mixin transform($transforms) 
  -webkit-transform: $transforms
  -moz-transform: $transforms
  -ms-transform: $transforms
  transform: $transforms

// Rotate
@mixin rotate ($deg)  
  @include transform(rotate($deg))

// Scale
@mixin scale($scale)
  @include transform(scale($scale))

// Translate
@mixin translate ($x, $y) 
  @include transform(translate($x, $y))

// Skew
@mixin skew ($x, $y) 
  @include transform(skew($x, $y))

// EDITING
@mixin editing_border_parent($color)
  border: solid 2px transparent
  border-style: dashed
    
@mixin editing_border_parent_larger($color)
  border: solid 3px transparent
  border-style: dashed

@mixin dottedBg($tr, $fil)
  background-image: linear-gradient(45deg, $tr 4.55%, $fil 4.55%, $fil 50%, $tr 50%, $tr 54.55%, $fil 54.55%, $fil 100%)
  background-size: 31.11px 31.11px
  z-index: $z_editing_bg

@mixin border_padding
  border: solid lighten($theme_blue, 20%) 1px

@mixin dropArea
  $tr: $theme_blue
  $fil: rgba(0,0,0,0)
  @include editing_border_parent($theme_orange)
  @include dottedBg($tr, $fil)

@mixin listStyle
  ul > li, ol > li, li > ul, li > ol, ul > ol, ol > ul, ol > ol, ul > ul
    margin-left: 20px
  ul > li
    list-style-type: disc
  ul > ul > li
    list-style-type: circle
  ul > ul > ul > li
    list-style-type: square
  ul > ul > ul > ul > li
    list-style-type: square
  ul > ul > ul > ul > ul > li
    list-style-type: square

  ol > li
    list-style-type: decimal

  & > div
    width: 100%

@mixin suddleBorder
  border: 1px solid rgba(0,0,0,.12)
  border-radius: 5px
  
@mixin suddleBorderHovered
  border: 1px solid rgba(0,0,0,.29)

@mixin suddleShadow
  box-shadow: 0px 0px 13px -10px rgba(0,0,0,0.75)

@mixin hoverScale($s:1.04)
  &:hover
    @include scale($s)

@mixin flexCenter
  display: flex
  justify-content: center
  align-items: center

@mixin tagStyle
  background: rgba(0,0,0,.15)
  padding: 2px 10px
  border-radius: 10px
  cursor: pointer
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  max-width: 150px



    
// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "")
  $index: str-index($string, $search)
  @if $index
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace)
  @return $string

// =============================================================================
// Font Face
// =============================================================================

=font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg)
  $src: null
  $extmods: (eot: "?", svg: "#" + str-replace($name, " ", "_"))
  $formats: (otf: "opentype", ttf: "truetype")
  @each $ext in $exts
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext)
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext)
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma)
  @font-face
    font-family: quote($name)
    font-style: $style
    font-weight: $weight
    src: $src