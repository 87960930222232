@import "variables"

// Color variables
$main_color: $theme_black
$fadedwhite: rgba(255,255,255,.7)
$secondary_color: lighten($theme_black, 20%)

#loginWrapper
  height: 100vh

  .loginWrapper_backgroundImage
    min-width: 100%
    min-height: 100%

  // App style  
  .Modal
    position: absolute
    top: 50%
    left: 50%
    padding: 45px 30px
    height: 190px
    width: 160px
    transform: translate(-50%, -50%)
    color: $theme_grey
    background-size: cover
    border-radius: 5px
    box-shadow: 0px 0px 200px 30px rgba(0,0,0,0.75)
    h3
      text-align: center
    .errorMsg
      font-size: $font_small
      color: mix(lighten($secondary_color, 20%), $theme_red, 10%)
      text-align: center
      top: 10px
      @include trans_centerX
    .signInButton
      padding: 5px 10px
      width: 80px 
    & a
      display: block
      color: $fadedwhite
      font-size: 12px
      text-align: center
      text-decoration: none
      margin: 4px 0 10px 0
      margin: 10px
      &:hover
        text-decoration: underline

  // Input  
  .Input
    display: flex
    text-align: center
    // Hack to be able to have a label pseudo element before the input field
    flex-direction: row-reverse
    border: none
    border-bottom: 1px solid $theme_grey
    align-items: center
    color: $theme_white
    margin: 0
    width: 100%
    height: 20px
    line-height: 20px
    font-weight: 100
    font-size: 15px
    background: transparent
    &:first-of-type
      margin: 20px 0
    &::placeholder
      color: $theme_grey
      font-weight: 100


    
    ::placeholder
      color: darken($font_color, 20%)
    & input
      border: 0
      background: transparent
      padding: 2px 10px
      margin: 18px 4px 0 4px
      color: $fadedwhite
      font-size: 14px
      
      &:required
        box-shadow: none
      
      // Animation trigger for label::before
      &:focus ~ label
        opacity: 1
      
  // Label
  label
    color: white
    font-size: 14px
    margin-top: 17px
    transition: opacity .3s ease-in-out
    // pseudo element before input field (see hack above)
    &::before
      padding: 5px 5px 0 0
      opacity: 0.5

  // Buttons

  // Sign in button
  form 
    & button
      position: relative
      margin: 15px 0 5px 0
      left: 50%
      transform: translateX(-50%)
      background: rgba(0,0,0,.6)
      border: none
      border-radius: 5px
      padding: 10px
      color: white
      width: 85%
      font-size: 14px
      cursor: pointer
      
  // Social login buttons    
  .social-signin
    text-align: center
    & button
      width: 40%
      border: none
      border-radius: 5px
      cursor: pointer
      padding: 10px
      & i
        font-size: 20px
        color: white
  // modal back button
  .bringitback
    position: absolute
    left: 50%
    top: 50%
    transform: translateX(-50%)
    border: none
    border-radius: 5px
    padding: 10px
    color: white
    width: 200px
    font-size: 14px
    cursor: pointer 

  // Fake logo
  .logo
      text-align: center
      color: white
      display: flex
      flex-direction: column
      & i
          display: block
          padding: 20px 0 0 0 
          font-size: 70px
          transform: translateX(-5px)
      & span
          text-transform: uppercase
      img
          width: 120px
          margin: auto
          margin-bottom: 20px
      
  // React CSS animations

  // On enter
  .animation-enter
    opacity: .01
    
    &.animation-enter-active
      opacity: 1
      transition: opacity .6s ease, margin .5s ease
    
  // On leave
  .animation-leave
    margin-top: 0px
    opacity: 1
    &.animation-leave-active
      margin-top: -30px
      opacity: .01
      transition: opacity .2s ease

  // On mount
  .animation-appear
    opacity: 0.01
  .animation-appear.animation-appear-active 
    opacity: 1
    transition: opacity .5s ease-in

  .loginQuestion_wrapper
    display: flex
    flex-direction: column
    .loginQuestion_question
      font-weight: 100
      padding: 10px
      margin-top: 20px
      font-size: $font_title
      color: lighten($secondary_color, 20%)
      text-align: center
    .loginQuestion_buttons
      display: inline-flex
      display: flex
      flex-wrap: wrap
      flex-direction: row
      padding: 10px 20px
      .loginQuestion_form
        padding: 20px
        // display: inline-flex
        button
          cursor: pointer
          border-radius: 3px
          height: 30px
          padding: 0
          &:hover
            background: lighten($secondary_color, 20%)