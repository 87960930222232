@import '../variables.sass'
    
.GoogleMaps_class
    margin: -25px
    width: calc(100% + 50px)
    &>div
        @include filler
        
.GoogleMaps_class button[title="Close"]
    display: none !important
