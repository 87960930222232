@import "../variables"

.TitlePage_class
    z-index: $z_normal
    overflow: hidden
    background: none
    // min-height: 100vh
    height: 100vh
    align-items: center
    display: flex

    .brand
        text-align: center
        position: relative
        display: flex
        flex-direction: column
        color: rgb(230, 230, 230)

    .brand a 
        display: inline-block

    #header
        display: flex
        top: 30px
        // margin-top: -50px !important
        width: 100%
        justify-content: center
        margin-bottom: 300px
        margin-top: 30px
        // height: 85.8vh
        // min-height: 600px
        position: relative
        z-index: 1

    #videoTextContainer
        overflow: hidden
        width: auto
        margin-left: 20px
        margin-right: 20px
        background: rgba(0, 0, 0, 0.7)
        padding: 30px
        width: 100%

    #videoText1
        color: #96b200
        padding-left: 0
        padding-right: 0
        padding-bottom: 50px
    
        
        
    .heading-1, .heading-3, .heading-6 
        opacity: 1
        font-family: "Open Sans", sans-serif
        font-weight: 300

    .heading-1 
        font-size: 40px
        line-height: 1.3

    @media (min-width: 768px) 
        .brand .heading-1 
            font-size: 76px
            line-height: 106px
    
    @media (min-width: 1250px) 
        .brand .heading-1 
            font-size: 90px
    

    @media (min-width: 1500px) 
        .brand .heading-1 
            /*font-size: 110px*/
            line-height: 106px
    

    .brand .heading-6 
        margin-top: 20px
        letter-spacing: 12px
        font-size: 15px

    @media (min-width: 1500px) 
        .brand .heading-6 
            font-size: 20px
    

    .brand .heading-3 
        padding: 6vh 39px 5vh 31px
        font-weight: 300
        font-size: 28px
        line-height: 1.3

    @media (min-width: 1200px) 
        .brand .heading-3 
            padding: 15.5vh 39px 10.4vh 31px
            font-size: 50px
            line-height: 50px
    

    @media (min-width: 1500px) 
        .brand .heading-3 
            /*font-size: 70px*/ 
            line-height: 70px
  
.bottomArrow
    z-index: $z_medium
    position: absolute
    width: 100%
    height: 40px
    font-size: 20px
    line-height: 36px
    text-align: center
    bottom: 5px
    cursor: pointer
    border-radius: 2px
    left: 50%
    transform: translateX(-50%)
    bottom: 0 

.bottomArrow img
    width: auto !important

.prettauTitlePage
    @include filler

    img
        z-index: $z_normal
        @include imageCover

    .prettauTitlePage_obscurer
        background: rgba(0,0,0,1)
        position: absolute
        top: 0
        left: 0
        z-index: $z_normal + 10
        @include filler
    // ANIMATION OBSCURER
    .obscurer-enter-done
        &.prettauTitlePage_obscurer
            transition: background 4s ease-out
            background: rgba(0,0,0,.6)

    .prettauTitlePage_texts
        text-transform: uppercase
        width: 100%
        @include trans_centerY
        z-index: $z_normal + 20
        color: white
        .prettauTitlePage_title, .prettauTitlePage_subtitle
            width: 100%
            opacity: 0
            text-align: center
        .prettauTitlePage_title
            font-size: 5.4rem
        .prettauTitlePage_subtitle
            font-size: 1rem
            font-weight: 600
            letter-spacing: 0.25em
            max-width: 500px
            margin: auto
        // BREAKPOINTS
        &.tablet, &.tabletLandscape
            .prettauTitlePage_title
                font-size: 4.5rem
        &.mobile, &.mobileLandscape
            .prettauTitlePage_title
                font-size: 2.9rem
            .prettauTitlePage_subtitle
                font-size: .8rem
        // &.desktop, &.desktopLarge, &.desktopWide
    // ANIMATION TEXTS
    .title-enter-done
        &.prettauTitlePage_title, &.prettauTitlePage_subtitle
            opacity: 1
            transition: opacity 1s ease-out
    