@import "../variables"

.registerTableContainer
    overflow: auto
    justify-content: center
    align-items: center
    display: flex

.registerTable
    margin: auto
    background-color: transparent
    &.autoWidth
        width: auto !important
    th, td 
        text-align: center !important
        padding: 12.5px 30px !important
    .registerTable_registerButton 
        border: none
        background: transparent
        cursor: pointer
        transition: transform .3s ease-out
        text-transform: uppercase
        &:hover
            @include scale(1.05)


.cityWrapper
    position: relative

.cityImage
    img 
        max-width: 150px
        margin: auto

.transTableWrapper
    width: 100%
    position: relative
    z-index: 50
    overflow: hidden
    display: flex
    flex-flow: row wrap
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    padding-top: 15px
    box-sizing: border-box
    padding: 15px 0
    justify-content: center

    .filler
        width: 100%
        height: 100%
        position: relative

    .transTableBox_wrapper
        padding: 10px
        // margin: auto

    .transTableBox
        cursor: pointer
        height: 250px
        width: 300px
        border: solid rgb(214, 214, 214) 1px
        box-sizing: border-box
        position: relative
        background-size: cover
        border-radius: 3px
        color: rgb(214, 214, 214)
        overflow: hidden

    .transTableBox_filler
        position: absolute
        background-size: cover !important
        background-position: center
        background-repeat: no-repeat
        z-index: $z_tiny + 10
        opacity: .2
        transition: opacity 1.3s

    .transTableBox:hover .transTableBox_filler
        opacity: .6
        transition: opacity 1.3s

    .transTableBox_content
        padding: 10px
        &:hover .transTableBox_filler
            opacity: 1
            transition: opacity 1.3s
            padding: 10px
            z-index: 100
            position: relative
            width: 100%
            height: 100%
            box-sizing: border-box
            word-wrap: break-word

    .transTableBox_venueTitle
        line-height: 25px
        font-size: 25px
        font-weight: bold
        margin-bottom: 0
        margin-top: 10px

    .transTableBox_venueDate
        margin-top: 5px
        font-size: 14px
        font-style: italic
        font-weight: 100

    .transTableBox_venueName
        margin-top: 5px
        font-size: 17px
        

    .transTableBox_speakersContainer
        position: absolute
        bottom: 20px

    .transTableBox_speakersTitle
        margin: 30px 0 10px 0

    .transTableBox_speaker
        margin: 0
        font-size: 14px

    .transTableBox_register
        position: absolute
        cursor: pointer
        transform: translateX(-50%)
        left: 50%
        font-size: 14px
        color: rgb(230, 165, 45)
        bottom: 5px