@import "../variables"

.Footer_class
    backgroundrepeat: no-repeat
    align-items: center
    min-height: 500px
    max-height: 744px
    position: relative
    text-align: center
    display: flex
    color: $theme_white
    margin: -25px
    width: calc(100% + 50px)

    .container
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        margin: auto

    .brand
        display: flex
        align-items: center
        justify-content: center
        flex-wrap: wrap

    .inline-list
        list-style-type: square
        text-align: center

        .inline-list-item
            margin: 10px
            display: inline-block
            list-style-position: outside
            list-style-type: square
            margin-left: 1em
            position: relative
            
            .footer_icon_small
                right: 0
                margin-top: -3px
                @include trans_centerXY
                color: $theme_white
                &:hover
                    color: darken($theme_white, 15%) !important
                & svg
                    height: 25px
                    width: 25px

        .social-link
            transition: 1s background
            font-size: 25px
            line-height: 77px
            width: 77px
            height: 77px
            display: inline-block
            background: #202020
            border-radius: 50%
            color: white

            &.facebook:hover
                background: #3B5998 !important
                
            &.instagram:hover 
                background: #fb3958 !important
                
            &.twitter:hover 
                background: #0084b4 !important
                
            &.youtube:hover 
                background: #ff0000 !important

        .copyright
            color: white
            font-weight: 100
            
    .zzFooter_backgroundImage
        position: absolute
        @include imageCover
        z-index: $z_neg

    .zzFooter_logo
        width: 250px
        max-height: 50px
        max-width: 80%
        cursor: pointer


    .zzFooter_signature
        cursor: pointer
        margin-left: 30px
        width: 200px
        height: 100px
        padding-top: 20px
        max-width: 60%


.GoogleMaps_class
    min-height: 400px