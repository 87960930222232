@import "../variables"

.RegButtonWhite
    position: relative

    //HOVER TRANSITION
    $hover: all 0.2s ease-in
    $slide: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1)

    //COLORS
    $grey: #4f4f4f
    $white: #ffffff
    $green: #39bda7
    $transparent: rgba(255, 255, 255, 0)
    $black-05: rgba(0, 0, 0, 0.05)

    a
        text-decoration: none
        color: $grey

    //ELEMENTS
    .RegButtonWhite__button-container
        padding: 100px 0
        text-align: center

    .RegButtonWhite__button
        display: inline-block
        padding: 12px 24px
        border: 1px solid $grey
        border-radius: 4px
        transition: $hover
        position: relative
        overflow: hidden

        &:before
            content: ""
            position: absolute
            left: 50%
            transform: translateX(-50%) scaleY(1) scaleX(1.25)
            top: 100%
            width: 140%
            height: 180%
            background-color: $black-05
            border-radius: 50%
            display: block
            transition: $slide
            z-index: -1

        &:after
            content: ""
            position: absolute
            left: 55%
            transform: translateX(-50%) scaleY(1) scaleX(1.45)
            top: 180%
            width: 160%
            height: 190%
            background-color: $green
            border-radius: 50%
            display: block
            transition: $slide
            z-index: -1

        &:hover
            color: $white
            border: 1px solid $green

            &:before
              top: -35%
              background-color: $green
              transform: translateX(-50%) scaleY(1.3) scaleX(0.8)

            &:after
              top: -45%
              background-color: $green
              transform: translateX(-50%) scaleY(1.3) scaleX(0.8)
