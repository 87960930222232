// // @import compass/css3

table.customTable_table
  max-width: 100%
  overflow: auto
  margin: 25px auto
  border-collapse: collapse
  border: 1px solid #eee
  border-bottom: 2px solid rgba(0,0,0,.4)
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.05), 0px 30px 20px rgba(0, 0, 0, 0.05)
  &.dispBlock
    display: block
  tr
    &:hover
      background: #f4f4f4
  th, td
    text-align: center
    vertical-align: middle
    color: #555
    border: 1px solid #eee
    padding: 12px 35px
    border-collapse: collapse
    &.left
      text-align: left
    &.nowrap
      white-space: nowrap
  th
    background: rgba(0,0,0,.4)
    // background: #00cccc
    color: #fff
    text-transform: uppercase
    font-size: 12px
    &.last
      border-right: none