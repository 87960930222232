@import '../variables.sass'

.image-gallery.fullscreen-modal
  z-index: $z_gallery

.image-gallery
  width: 100%
  .image-gallery-slide
    background: transparent
  img
    max-height: calc(100vh - 110px)
    object-fit: contain
    overflow: hidden
    object-position: center center

.fullscreen .image-gallery-slide img
  max-height: calc(100vh - 110px) !important

.image-gallery-content.fullscreen
  top: 50% 
  transform: translateY(-50%)

