@import "../../variables" 
  
.foregroundElement_wrapper
  z-index: $z_pageElement_foreground
  font-size: initial
  overflow: hidden
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"
  letter-spacing: normal
  position: absolute
  top: 0
  right: 0
  display: flex
  justify-content: center
  align-items: center
  transition: transform .4s ease-out, background .4s
  width: 100%
  height: 100%
  .foregroundElement_dragSource_wrapped
    position: absolute
    width: 100%
    height: 100%
    cursor: move
  &.hovered
    background: orange !important

  .foreground_titleContainer
    position: relative

  .foreground_icon
    position: absolute
    padding: 5px
    border-radius: 20px
    padding: 5px
    &.selected
      border: 2px solid black !important
    &.highlighted
      svg
        color: $secondary_color
    &:hover
      cursor: pointer
    svg
      padding: 1px
    &.comments
      top: -9px
      left: -12px
    &.settings
      bottom: -9px
      right: -12px
    &.trash
      top: -9px
      right: -12px
    &.duplicate
      bottom: -9px
      left: -12px

.foreground_CommentWrapper
  z-index: $z_pageElement_foreground + 10
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75)
  padding: 10px
  border-radius: 5px
  font-size: 13px
  background: white
  &.arrow-up
    margin-top: 10px
  &.arrow-down
    margin-bottom: 10px
  .foreground_CommentInputForm
    display: flex
    form
      display: flex
    button
      display: flex
      align-items: center
  .arrow-up, .arrow-down
    width: 0
    height: 0
    @include trans_centerX
  .arrow-up
    border-left: 10px solid transparent
    border-right: 10px solid transparent
    border-bottom: 10px solid white
    bottom: 100%
  .arrow-down
    border-left: 10px solid transparent
    border-right: 10px solid transparent
    border-top: 10px solid white
    top: 100%
  .commentPopper_header
    display: flex
    justify-content: space-between
    background: rgba(0,0,0,.06)
    padding: 2px 5px
    margin: 0 -5px 10px -5px
    font-size: 14px
    align-items: center
    .simpleIcon_class
      cursor: pointer
  .foreground_CommentTableWrapper
    max-height: 250px
    overflow-x: hidden
    overflow-y: auto
    table
      max-width: 370px
      width: 100%
      display: inline-table
      margin-right: 20px
      td
        width: auto
        padding-bottom: 15px
        &.minWidth
          width: 1%
          white-space: nowrap
          max-width: 100px
          overflow: hidden
      .commentTable_user_time
        word-break: break-word
        max-width: 150px
        text-align: right
        padding-right: 10px
        .commentTable_user
          font-size: 12px
          font-weight: bold
        .commentTable_time
          font-size: 10px
      .commentTable_user_text
        font-size: 14px
      .commentTable_remove
        cursor: pointer
        max-width: 12px
        font-size: 12px

  
